<template>
  <div class="formBox" v-loading="loading">
    <!-- <div style="display: flex; align-items: center">
      <h4 style="color: #146aff; padding: 0px 20px 0px 30px">基本信息</h4>
    </div> -->
    <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
      <el-form-item label="商机类别" prop="businessOpportunityCategory">
        <el-radio-group v-model="form.businessOpportunityCategory">
          <el-radio :label="1">潜在需求</el-radio>
          <el-radio :label="2">真实需求</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商机类型" prop="businessOpportunityType">
        <el-radio-group v-model="form.businessOpportunityType">
          <el-radio :label="1">数智产品</el-radio>
          <el-radio :label="2">政策申报</el-radio>
          <el-radio :label="3">专家咨询</el-radio>
          <el-radio :label="4">人才寻访</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="封面图" prop="businessImage">
        <div class="cover">
          <div class="img_box">
            <uploadCoverOne
              :fileList="form.businessImage"
              @uploadOneImg="uploadOneImg"
              @removeImg="removeImg"
            ></uploadCoverOne>
            <span style="color: #d4d4d4">建议图片整体尺寸为442*226 px大小不超过12MB</span>
          </div>
          <div v-if="imageUrlList.length > 0">缩略图</div>
          <div v-if="imageUrlList.length > 0" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：文章详情页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="商机名称" prop="businessName">
        <el-input v-model="form.businessName" placeholder="请输入商机名称"></el-input>
      </el-form-item>
      <el-form-item label="商机描述" prop="businessOpportunityDescription">
        <el-input
          v-model="form.businessOpportunityDescription"
          placeholder="请输入商机描述"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
          maxlength="1000"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="企业价值" prop="">
        <el-input
          v-model="form.businessEnterpriseValue"
          placeholder="请输入企业价值"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
          maxlength="1000"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="button_box">
      <div class="save button" @click="clickSave">保存</div>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import { nicheAdd, nicheUpdate, nicheListById } from '@/api/operatorCenter.js'
import uploadCoverOne from './uploadCoverOne'
export default {
  components: { uploadCoverOne },
  props: {
    businessId: {
      type: String
    }
  },
  data() {
    return {
      loading: false, //loadding
      form: {
        businessId: null, // 编号
        businessOpportunityCategory: 1,
        businessOpportunityType: 1,
        businessImage: '', // 封面图
        businessName: '', // 商机名称
        businessOpportunityDescription: '', // 商机描述
        businessEnterpriseValue: '' // 企业价值
      },
      imageUrlList: [],
      updatePwdRules: {
        businessOpportunityCategory: [
          { required: true, message: '请选择商机类别', trigger: 'change' }
        ],
        businessOpportunityType: [{ required: true, message: '请选择商机类型', trigger: 'change' }],
        businessImage: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        businessName: [{ required: true, message: '请输入商机名称', trigger: 'blur' }],
        businessOpportunityDescription: [
          { required: true, message: '请输入商机描述', trigger: 'blur' }
        ]
        // businessEnterpriseValue: [{ required: true, message: '请输入企业价值', trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    if (this.businessId) {
      this.form.businessId = this.businessId
      this.getSelectById()
    }
  },
  mounted() {},
  //方法集合
  methods: {
    async getSelectById() {
      const { data: res } = await nicheListById({ businessId: this.businessId })
      if (res.resultCode === 200) {
        this.form = res.data
        this.imageUrlList.push(this.form.businessImage)
      } else {
        this.$message.error('获取详情失败！')
      }
    },

    // 保存
    clickSave() {
      if (this.imageUrlList.length > 0) {
        this.form.businessImage = this.imageUrlList[0]
      }

      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const fn = this.businessId ? nicheUpdate : nicheAdd
          const txt = this.businessId ? '编辑' : '新增'
          const { data: res } = await fn(this.form)
          if (res.resultCode === 200) {
            this.$message.success(txt + '成功！')
            this.clickCancel()
          } else {
            this.$message.error(txt + '失败！')
          }
        }
      })
    },
    // 取消
    clickCancel() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/operatorcenter/nicheexcavate',
        query: {
          changeNum: true
        }
      })
    },
    //图片上传显示
    uploadOneImg(val) {
      this.imageUrlList = []
      val.forEach((item) => {
        this.imageUrlList.push(item.url)
      })
    },
    // 移除
    removeImg(val) {
      this.imageUrlList = []
    }
  }
}
</script>
<style scoped lang="less">
.formBox {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  position: relative;

  ::v-deep .textarea .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 480px;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ::v-deep .percentage .el-input {
    width: 200px;
    height: 40px;
  }

  ::v-deep .textarea .el-textarea {
    position: relative;
    display: inline-block;
    width: 480px;
    vertical-align: bottom;
    font-size: 14px;
  }
  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .cover {
    display: flex;
    flex-direction: column;
    ::v-deep .img_box .el-upload--picture-card {
      width: 135px;
      height: 92px;
      border-radius: 2px;
      border: none;
      text-align: center;
      line-height: 92px;
      font-size: 14px;
      color: #4e93fb;
      display: inline-block;
      background-color: #f4f4f4;
    }
    ::v-deep .img_box .el-upload-list .el-upload-list__item {
      width: 135px;
      height: 92px;
      border: none;
    }
  }

  .button_box {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
}
.thumbnail {
  display: flex;
  color: #bebebe;
  img {
    width: 135px;
    height: 92px;
    border-radius: 4px;
  }
  video {
    width: 160.28px;
    height: 91.35px;
    border-radius: 4px;
  }
  div {
    margin-right: 20px;
  }
  .thumbnail_box div {
    display: flex;
    flex-direction: column;
    height: 30px;
  }
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}

::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}
</style>
